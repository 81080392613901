<template>
  <v-container grid-list-xl fluids>
    <header-title title="Panel Informativo"></header-title>
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nro</th>
                <th class="text-left">Descripción</th>
                <th class="text-left">Documento</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in documentos" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.descripcion }}</td>
                <td>
                  <a
                    :href="serve + '/normativas/' + item.documento"
                    target="_blank"
                    class="link-docs"
                  >
                    Enlace del archivo
                  </a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import HeaderTitle from "@/components/HeaderTitle";
  import Service from "../services/general";
  import axios from "axios";
  export default {
    name: "panel-informativo",
    components: {
      HeaderTitle,
    },
    data: () => ({
      loadingFile: false,
      serve: "",
      documentos: [],
    }),
    created() {
      this.serve = Service.getServe();
    },
    mounted() {
      this.getDocumentos();
    },
    methods: {
      getDocumentos() {
        this.loadingFile = true;
        axios
          .get(Service.getBase() + "normativos/activo", Service.getHeader())
          .then(response => {
            this.loadingFile = false;
            this.documentos = response.data;
          })
          .catch(error => {
            this.loadingFile = false;
            console.error("Error al cargar registros", error);
          });
      },
    },
  };
</script>
<style lang="css">
  .link-docs {
    text-decoration: none;
  }
</style>
